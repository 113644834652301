import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import EnvUtil from "../utils/EnvUtil";
import { CONFIG } from "./ConfigFile";
import { getAuth } from "firebase/auth";

const env = EnvUtil.getEnv(window.location.href);

// Initialize Firebase
export const app = initializeApp(CONFIG[env]);
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        "6LcLfHonAAAAAFzy9KWkIYMbosZgl5CUbTmxUKs8"
    ),
    isTokenAutoRefreshEnabled: true,
});
export const functions = getFunctions(app, "europe-west2");
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
analytics.app.automaticDataCollectionEnabled = true;

if (process.env.REACT_APP_FIREBASE_EMULATOR) {
    connectFunctionsEmulator(functions, "localhost", 5001);
}
