import { Navigate, useLocation } from "react-router-dom";
import { useBoundStore } from "../store";

const AuthRequired = ({ children }: { children: JSX.Element }) => {
    const { isAuthenticated } = useBoundStore();
    const location = useLocation();
    return isAuthenticated ? (
        children
    ) : (
        <Navigate to="/" state={{ from: location }} replace />
    );
};

export default AuthRequired;
