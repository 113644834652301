import {
    Timestamp,
    doc,
    getDoc,
    serverTimestamp,
    updateDoc,
} from "firebase/firestore";
import { StateCreator } from "zustand";
import { firestore } from "../config/firebase";

/**
 * @typedef {Object} Customer
 * @property {string} name
 * @property {string} email
 * @property {string} id
 * @property {Timestamp} created_at
 * @property {ApiDeveloper[]} developers
 * @property {Object} config
 */

export interface ApiDeveloper {
    id: string;
    uid: string;
    name: string;
    email: string;
    createdAt: Timestamp;
}

export interface Customer {
    name: string;
    email: string;
    id: string;
    created_at: Timestamp;
    developers: ApiDeveloper[];
    config: { [field: string]: any };
    country: string;
    phone: string;
    active: boolean;
    chat_flow: string;
}

export interface WebUiConfig {
    guidance_title?: string;
    guidance_content: string;
    faq_title: string;
    faq_content: string;
    logo_url: string;
    terms_summary_id: string;
    full_terms_id: string;
    privacy_policy_id: string;
    faq_id: string;
    is_end_page_required: boolean;
    end_page_header: string;
    end_page_content: string;
}

export type AssessmentStatus = "CLOSED" | "OPEN" | "COMPLETED";

export interface Referral {
    id: string;
    created_at: Timestamp;
    customer_id: string;
    customer_name: string;
    delivered: boolean;
    status: AssessmentStatus;
    referral_url?: string;
    has_accepted_terms?: boolean;
    first_name: string;
    last_name: string;
    closure_reason?: string;
}

export interface AppSlice {
    getCustomerById: (uid: string) => Promise<Customer | undefined>;
    currentCustomer: Customer | undefined;
    webUiConfigData: WebUiConfig | undefined;
    getWebUiConfig: (uid: string) => Promise<WebUiConfig | undefined>;
    getReferralDocument: (referralId: string) => Promise<Referral | undefined>;
    referral: Referral | undefined;
    updateReferral: (
        uid: string,
        values: Referral
    ) => Promise<Referral | undefined>;
    progress: number;
    setProgressValue: (value: number) => void;
}

const initialAppState = {
    currentCustomer: undefined,
    webUiConfigData: undefined,
    referral: undefined,
    progress: 0,
};

export const createAppSlice: StateCreator<AppSlice> = (set, get) => ({
    ...initialAppState,
    getCustomerById: async (uid: string): Promise<Customer | undefined> => {
        try {
            const ref = doc(firestore, "customers", uid);
            const currentCustomer = await getDoc(ref);
            const currentCustomerData = {
                ...currentCustomer.data(),
                id: currentCustomer.id,
            } as Customer;
            set({ currentCustomer: currentCustomerData });
            return currentCustomerData;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    getWebUiConfig: async (uid: string): Promise<WebUiConfig | undefined> => {
        try {
            const ref = doc(firestore, "customers", uid, "web_ui_configs", uid);
            const config = await getDoc(ref);
            const configData = config.data() as WebUiConfig;
            set({ webUiConfigData: configData });
            return configData;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    },
    getReferralDocument: async (
        referralId: string
    ): Promise<Referral | undefined> => {
        try {
            const ref = doc(firestore, "referrals", referralId);
            const referralRes = await getDoc(ref);
            const referralData = {
                ...referralRes.data(),
                id: referralRes.id,
            } as Referral;
            set({ referral: referralData });
            return referralData;
        } catch (e) {
            console.error(e, "error getting referral document");
            return undefined;
        }
    },
    updateReferral: async (
        referralId: string,
        values: Referral
    ): Promise<Referral | undefined> => {
        try {
            const ref = doc(firestore, "referrals", referralId);
            const document = await getDoc(ref);

            if (document.exists()) {
                await updateDoc(ref, {
                    ...values,
                    updated_at: serverTimestamp(),
                });
                return;
            }
        } catch (e) {
            console.error(e);
        }
    },
    setProgressValue: (value: number) => {
        set({ progress: value });
    },
});
