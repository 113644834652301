import { Box, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import eqlLogo from "../../assets/images/eql.png";

const Root = () => {
  return (
    <Box>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={eqlLogo}
          alt="Phio logo"
          sx={{
            maxWidth: 300,
            marginTop: "48px",
            marginBottom: "32px",
          }}
        />
      </Container>
    </Box>
  );
};

export default Root;
