import { Box } from "@mui/material";

interface LogoProps {
    logoUrl?: string;
    logoAlt?: string;
    size?: "small" | "medium" | "large";
}

const logoSizes = {
    small: { xs: "34px", sm: "42px" },
    medium: { xs: "44px", sm: "52px" },
    large: { xs: "64px", sm: "82px" },
};

const Logo = (props: LogoProps) => {
    const { logoUrl, logoAlt, size = "large" } = props;

    if (!logoUrl) {
        return null;
    }

    return (
        <Box
            component="img"
            sx={{
                height: "100%",
                maxHeight: logoSizes[size],
            }}
            src={logoUrl}
            alt={logoAlt || "Logo"}
        />
    );
};

export default Logo;
