/**
 * @class EnvUtil
 * Represents EnvUtil.
 */
class EnvUtil {
    /**
     * Returns the environment based on the url
     * @param {string} url - url to check
     * @returns {string}
     */
    static getEnv(url: string): "localhost" | "dev" | "prod" {
        if (EnvUtil.isLocalhost(url)) {
            return "localhost";
        } else if (EnvUtil.isDev(url)) {
            return "dev";
        } else {
            return "prod";
        }
    }

    /**
     * Checks if the url is localhost
     * @param {string} url - url to check
     * @returns {boolean}
     */
    static isLocalhost(url: string): boolean {
        return url.indexOf("localhost") > -1;
    }

    /**
     *
     * @param {string} url - url to check
     * @returns {boolean}
     */
    static isDev(url: string): boolean {
        return url.indexOf("dev") > -1 || url.indexOf("staging") > -1;
    }
}

export default EnvUtil;
