import { createTheme } from "@mui/material";
import IBMPlexSansBold from "../assets/fonts/IBMPlexSans/IBMPlexSans-Bold.otf";
import IBMPlexSansMedium from "../assets/fonts/IBMPlexSans/IBMPlexSans-Medium.otf";
import IBMPlexSansLight from "../assets/fonts/IBMPlexSans/IBMPlexSans-Light.otf";

export const phioBrandColors = {
  PRIMARY: "#424c5f",
  SECONDARY: "#233140",
  ACTIVE: "#4386f9",
  INPUTBORDER: "#94979a",
  DISABLED: "#74777a",
};

const fontFamily = 'IBMPlexSans,"Roboto","Helvetica","Arial",sans-serif';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default createTheme({
  breakpoints: {
    ...theme.breakpoints,
  },
  typography: {
    fontFamily,
  },
  palette: {
    mode: "dark",
    primary: {
      main: phioBrandColors.ACTIVE,
    },
    secondary: {
      main: "#ffffff",
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
                @font-face {
                    font-family: IBMPlexSans;
                    font-style: normal;
                    font-display: swap;
                    font-weight: 700;
                    src: url(${IBMPlexSansBold}) format("opentype");
                }
                @font-face {
                    font-family: IBMPlexSans;
                    font-style: normal;
                    font-display: swap;
                    font-weight: 500;
                    src: url(${IBMPlexSansMedium}) format("opentype");
                }
                @font-face {
                    font-family: IBMPlexSans;
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: url(${IBMPlexSansLight}) format("opentype");
                }
                html,
                body {
                    width: 100%;
                    height: 100%;
                    background-color: #233140;
                    overflow: -moz-scrollbars-none;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    letter-spacing: normal;
                    line-height: normal;   
                }
                #root {
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
                ul {
                    padding: 0;
                    margin: 0;
                }
            `,
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: "12px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: "1.7em",
          color: "#ffffff",
        },
        outlined: {
          color: phioBrandColors.INPUTBORDER,
          "&.Mui-focused": {
            color: "#ffffff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover $notchedOutline": {
            borderColor: "#ffffff",
          },
          "&$focused $notchedOutline": {
            borderColor: "#ffffff",
          },
        },
        input: {
          caretColor: "#ffffff",
          color: "#ffffff",
          "&::placeholder": {
            color: "#ffffff",
          },
        },
        notchedOutline: {
          borderColor: phioBrandColors.INPUTBORDER,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: phioBrandColors.PRIMARY,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          visibility: "hidden",
          [theme.breakpoints.up("sm")]: {
            visibility: "visible",
          },
        },
        line: {
          borderColor: "#bdbdbd",
        },
        lineVertical: {
          minHeight: "18px",
          marginBottom: "-10px",
          [theme.breakpoints.up("sm")]: {
            minHeight: "28px",
          },
        },
        vertical: {
          padding: "0",
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          position: "relative",
          color: "#ffffff",
          marginTop: "0",
          marginBottom: "0",
          marginLeft: "0",
          paddingLeft: "0",
          paddingRight: "0",
          borderLeft: "none",
          zIndex: 1,
          [theme.breakpoints.up("sm")]: {
            marginTop: "-10px",
            marginBottom: "-10px",
            marginLeft: "12px",
            paddingLeft: "27px",
            borderLeft: "1px solid #bdbdbd",
            "&.MuiStepContent-last": {
              borderColor: "transparent",
            },
          },
        },
        transition: {
          borderTop: "none",
          marginTop: "1px",
          backgroundColor: phioBrandColors.PRIMARY,
          padding: "16px",
          borderRadius: "0 0 4px 4px",
          [theme.breakpoints.up("sm")]: {
            marginTop: "0",
            borderTop: `11px solid ${phioBrandColors.SECONDARY}`,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.38)",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          position: "relative",
          zIndex: 10,
          padding: "0",
          "&.Mui-active": {
            "&$active": {
              color: "#ffffff",
            },
          },
          "&.Mui-completed": {
            backgroundColor: phioBrandColors.ACTIVE,
          },
        },
        iconContainer: {
          paddingRight: "16px",
          display: "none",
          [theme.breakpoints.up("sm")]: {
            display: "block",
          },
        },
        label: {
          display: "flex",
          height: "48px",
          boxSizing: "content-box",
          flexDirection: "row",
          alignItems: "center",
          color: "#ffffff",
          fontSize: "1rem",
          padding: "8px 16px",
          backgroundColor: phioBrandColors.PRIMARY,
          borderRadius: "4px",
          "&.Mui-active": {
            borderRadius: "4px 4px 0 0",
          },
          "&.Mui-completed": {
            backgroundColor: phioBrandColors.ACTIVE,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderColor: "rgba(255, 255, 255, 0.4)",
          "&.Mui-selected": {
            backgroundColor: phioBrandColors.ACTIVE,
          },
          "&.Mui-selected:hover": {
            backgroundColor: phioBrandColors.ACTIVE,
          },
          "&:hover": {
            backgroundColor: "rgba(67, 134, 249, 0.6)",
          },
        },
      },
    },
  },
});
