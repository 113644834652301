import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import EnvUtil from "../../utils/EnvUtil";

/**
 * Represents FbAnalytics
 * Firebase analytics wrapper
 */

export const ANALYTICS_EVENTS = {
    TERMS_SUMMARY_VISITED: "terms_and_conditions_summary_page_visited",
    TERMS_ACCEPTED: "terms_and_conditions_accepted",
    TERMS_DECLINED: "terms_and_conditions_declined",
    TERMS_DECLINED_CONFIRM: "terms_and_conditions_declined_confirm",
    TERMS_DECLINED_CANCEL: "terms_and_conditions_declined_cancel",
    COOKIE_POLICY_VISITED: "cookie_policy_page_visited",
    PRIVACY_POLICY_VISITED: "privacy_policy_page_visited",
    TERMS_VISITED: "terms_and_conditions_page_visited",
    MENU_OPEN: "chat_side_menu_open",
    MENU_CLOSED: "chat_side_menu_close",
    FAQ_LINK_CLICKED: "chat_faq_link_clicked",
    OUTCOME_PAGE_VISITED: "outcome_page_visited",
    FAQ_VISITED: "faq_page_visited",

    // Specific Question Opened - `faq_${question}`
};

export const fbLogEvent = (
    eventName: string,
    eventParams?: { [propName: string]: any }
) => {
    const analytics = getAnalytics();
    const env = EnvUtil.getEnv(window.location.href);
    logEvent(analytics, eventName, {
        ...eventParams,
        app_domain: env === "prod" ? "eql-genphlo" : "eql-genphlo-dev",
    });
};

export const setUserProperty = (values: { [propName: string]: any }) => {
    const analytics = getAnalytics();
    setUserProperties(analytics, { ...values });
};
