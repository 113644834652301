import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
    Notification,
    NotificationProviderProps,
    NotificationProviderValue,
} from "./types";

const NotificationsContext = createContext<NotificationProviderValue | null>(
    null
);

export function NotificationProvider(props: NotificationProviderProps) {
    const { children } = props;

    const [notification, setNotification] = useState<Notification>({
        message: "",
        variant: "info",
        autoHideDuration: 5000,
    });
    const [isOpen, setIsOpen] = useState(false);

    const providerValue = useMemo(
        () => ({ setNotification }),
        [setNotification]
    );

    const closeNotification = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (notification.message) {
            setIsOpen(true);
        }
    }, [notification]);

    return (
        <NotificationsContext.Provider value={providerValue}>
            {children}
            <Snackbar
                open={isOpen}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                autoHideDuration={
                    notification.autoHideDuration === undefined
                        ? 5000
                        : notification.autoHideDuration
                }
                onClose={closeNotification}
            >
                <Alert
                    onClose={closeNotification}
                    severity={notification.variant}
                    variant="filled"
                    sx={{ width: "100%" }}
                >
                    {notification.message}
                </Alert>
            </Snackbar>
        </NotificationsContext.Provider>
    );
}

export const useNotification = () => {
    const context = useContext(NotificationsContext);

    if (context === undefined || context == null) {
        throw new Error(
            "useNotification must be used within a NotificationProvider"
        );
    }

    return {
        setNotification: context.setNotification,
    };
};
