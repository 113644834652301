import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AppSlice, createAppSlice } from "./AppSlice";
import { AuthSlice, createAuthSlice } from "./AuthSlice";

export const useBoundStore = create<AppSlice & AuthSlice>()(
    devtools(
        persist(
            (...a) => ({
                ...createAppSlice(
                    // @ts-ignore
                    ...a
                ),
                ...createAuthSlice(
                    // @ts-ignore
                    ...a
                ),
            }),
            { name: "boundStore" }
        )
    )
);
