import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Link, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import poweredByEql from "../../assets/images/poweredByEql.png";
import Logo from "../Logo";

interface FooterProps {
    clientLogoUrl?: string;
    clientLogoAlt?: string;
    clientUrl?: string;
}

const Footer = (props: FooterProps) => {
    const { clientLogoUrl, clientLogoAlt, clientUrl } = props;

    const renderClientSection = () => {
        if (!clientLogoUrl) {
            return null;
        }

        return clientUrl ? (
            <>
                <a href={clientUrl} target="_blank" rel="noopener noreferrer">
                    <Logo logoUrl={clientLogoUrl} logoAlt={clientLogoAlt} />
                </a>
                <Box component="span" sx={visuallyHidden}>
                    {" "}
                    (opens in a new tab)
                </Box>
            </>
        ) : (
            <Logo logoUrl={clientLogoUrl} logoAlt={clientLogoAlt} />
        );
    };

    const renderPoweredBySection = () => (
        <Logo logoUrl={poweredByEql} logoAlt="Powered by EQL" />
    );

    const renderContent = () => {
        return (
            <Typography variant="body2" component="p">
                Read about how we manage your information in our{" "}
                <Link
                    to="/legal/privacy"
                    component={RouterLink}
                    target="_blank"
                    sx={{
                        fontWeight: "500",
                        color: "primary.main",
                        textDecoration: "underline",
                        "&:hover": {
                            color: "#ffffff",
                        },
                    }}
                    data-qa="footer-privacyPolicyLink"
                >
                    Privacy Policy
                    <Box component="span" sx={visuallyHidden}>
                        {" "}
                        (opens in a new tab)
                    </Box>
                </Link>{" "}
                or how we use Cookies to improve your experience in our{" "}
                <Link
                    to="/legal/cookie"
                    component={RouterLink}
                    target="_blank"
                    sx={{
                        fontWeight: "500",
                        color: "primary.main",
                        textDecoration: "underline",
                        "&:hover": {
                            color: "#ffffff",
                        },
                    }}
                    data-qa="footer-cookiePolicyLink"
                >
                    Cookie Policy
                    <Box component="span" sx={visuallyHidden}>
                        {" "}
                        (opens in a new tab)
                    </Box>
                </Link>
                .
            </Typography>
        );
    };

    const renderCookieButton = () => {
        return (
            <Box mt={2} textAlign="center">
                <Button
                    className="cky-banner-element"
                    variant="outlined"
                    size="small"
                    data-cky-tag="revisit-consent"
                >
                    manage cookies
                </Button>
            </Box>
        );
    };

    return (
        <Box mx={2}>
            <Box
                component="footer"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    textAlign="left"
                    pb={1}
                    flex="250px"
                    display={{ xs: "none", md: "block" }}
                >
                    {renderPoweredBySection()}
                </Box>
                <Box textAlign="center" pb={1} flex="auto">
                    {renderContent()}
                    {renderCookieButton()}
                </Box>
                <Box
                    textAlign="right"
                    pb={1}
                    flex="250px"
                    display={{ xs: "none", md: "block" }}
                >
                    {renderClientSection()}
                </Box>
            </Box>
            <Box display={{ xs: "flex", md: "none" }} mt={2}>
                <Box textAlign="left" pb={1} flex="1" width="50%">
                    {renderPoweredBySection()}
                </Box>
                <Box textAlign="right" pb={1} flex="1" width="50%">
                    {renderClientSection()}
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
