import { StateCreator } from "zustand";
import {
    User,
    signOut,
    signInWithCustomToken,
    UserCredential,
} from "firebase/auth";
import { auth } from "../config/firebase";
import { validateLink } from "../services/userRecord";

type InvalidResponse = {
    valid: boolean;
};

type ValidResponse = {
    token?: string;
};

type ValidateResponse = InvalidResponse & ValidResponse;

export interface AuthSlice {
    isAuthenticated: boolean;
    signInUsingToken: (token: string) => Promise<void>;
    signOut: () => Promise<void>;
    user: User | null;
    validateAuthToken: (token: string) => Promise<ValidateResponse>;
}

export const createAuthSlice: StateCreator<AuthSlice> = (set, get) => ({
    isAuthenticated: false,
    signInUsingToken: async (token: string) => {
        try {
            const credentials: UserCredential = await signInWithCustomToken(
                auth,
                token
            );
            set({ isAuthenticated: true });
            set({ user: credentials.user });
        } catch (e) {
            console.error(e);
        }
    },
    signOut: async () => {
        try {
            await signOut(auth);
            set({ isAuthenticated: false });
            set({ user: null });
        } catch (e) {
            console.error(e);
        }
    },
    user: null,
    validateAuthToken: async (token: string): Promise<ValidateResponse> => {
        try {
            const res = await validateLink(token);
            if (res.data.status === 200) {
                return {
                    valid: true,
                    token: res.data.custom_token,
                };
            }
            return { valid: false };
        } catch (e) {
            console.error("Trapped error", e);
            return { valid: false };
        }
    },
});
