import { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { PrismicProvider } from "@prismicio/react";
import router from "./routes/appRoutes";
import phioTheme from "./themes/phioTheme";
import { client } from "../src/utils/prismic/prismic";
import { NotificationProvider } from "./components/Notification";
import { setUserProperty } from "./services/analitics/FbAnalytics";

function App() {
    useEffect(() => {
        setUserProperty({ application: "genphlo" });
    }, []);

    return (
        <HelmetProvider>
            <ThemeProvider theme={phioTheme}>
                <CssBaseline />
                <NotificationProvider>
                    <PrismicProvider client={client}>
                        <RouterProvider router={router} />
                    </PrismicProvider>
                </NotificationProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
