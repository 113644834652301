import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import LoginLayout from "../components/LoginLayout";
import Root from "../containers/Root";
import AuthRequired from "./AuthRequired";
import RouteLoadingFallback from "./RouteLoadingFallback";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "../containers/ErrorPage/ErrorFallbackComponent";

const Auth = lazy(() => import("../containers/Auth"));
const Chat = lazy(() => import("../containers/Chat"));
const Cookie = lazy(() => import("../containers/Cookie"));
const PageNotFound = lazy(() => import("../containers/PageNotFound"));
const PrivacyPolicy = lazy(() => import("../containers/Privacy"));
const Faq = lazy(() => import("../containers/Faq"));
const Terms = lazy(() => import("../containers/Terms/Terms"));
const UserTerms = lazy(() => import("../containers/UserTerms/UserTerms"));
const ErrorPage = lazy(() => import("../containers/ErrorPage"));
const ExpiredPage = lazy(() => import("../containers/ExpiredPage"));
const OutcomePage = lazy(() => import("../containers/Chat/OutcomePage"));

const router = createBrowserRouter([
    { path: "/", element: <Root /> },
    {
        path: "/authenticate/:token",
        element: <Auth />,
    },
    {
        path: "/legal",
        element: <LoginLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/legal/faq",
                element: <Faq />,
            },
            {
                path: "/legal/privacy",
                element: <PrivacyPolicy />,
            },
            {
                path: "/legal/cookie",
                element: <Cookie />,
            },
            {
                path: "/legal/terms",
                element: <Terms />,
            },
            {
                element: (
                    <AuthRequired>
                        <UserTerms />
                    </AuthRequired>
                ),
                index: true,
            },
        ],
    },
    {
        path: "/",
        element: <LoginLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/outcome",
                element: (
                    <Suspense fallback={<RouteLoadingFallback />}>
                        <AuthRequired>
                            <OutcomePage />
                        </AuthRequired>
                    </Suspense>
                ),
            },
            {
                path: "/expired",
                element: <ExpiredPage />,
            },
        ],
    },
    {
        path: "/chat",
        element: (
            <Suspense fallback={<RouteLoadingFallback />}>
                <AuthRequired>
                    <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
                        <Chat />
                    </ErrorBoundary>
                </AuthRequired>
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },

    { path: "*", element: <PageNotFound /> },
]);

export default router;
