import { Box, Button, Container } from "@mui/material";
import { FallbackProps } from "react-error-boundary";

const ErrorFallbackComponent = ({
    error,
    resetErrorBoundary,
}: FallbackProps) => {
    return (
        <Container
            component="main"
            maxWidth="sm"
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box>
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <pre>{error?.message}</pre>
            </Box>
            <Button onClick={resetErrorBoundary}>Try again</Button>
        </Container>
    );
};

export default ErrorFallbackComponent;
