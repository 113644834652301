/* eslint-disable camelcase */
import { functions } from "../../config/firebase";
import { httpsCallable } from "firebase/functions";

export function validateLink(linkId: string) {
    return httpsCallable<
        { token: string },
        { status: number; custom_token: string }
    >(
        functions,
        "auth-validateAuthToken"
    )({
        token: linkId,
    });
}
