import { Box, Container } from "@mui/material";
import eqlLogo from "../../assets/images/eql.png";
import Logo from "../Logo";

interface HeaderProps {
  clientLogoUrl?: string;
  clientLogoAlt?: string;
}

const Header = (props: HeaderProps) => {
  const { clientLogoUrl, clientLogoAlt } = props;

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center" }} component="header">
      <Box
        sx={{
          marginTop: "40px",
          marginBottom: "32px",
        }}
      >
        <Logo
          logoUrl={clientLogoUrl || eqlLogo}
          logoAlt={clientLogoAlt || "EQL logo"}
        />
      </Box>
    </Container>
  );
};

export default Header;
