export const CONFIG: { [key: string]: any } = {
  localhost: {
    apiKey: "AIzaSyDa0yxyQYbY6ZNH_toQuQOXZh8F3rAmvLQ",
    authDomain: "eql-genphlo-dev.firebaseapp.com",
    projectId: "eql-genphlo-dev",
    storageBucket: "eql-genphlo-dev.appspot.com",
    messagingSenderId: "538219895140",
    appId: "1:538219895140:web:91128b03f6f12a5a578271",
    measurementId: "G-2MM58M6BHF",
  },
  dev: {
    apiKey: "AIzaSyDa0yxyQYbY6ZNH_toQuQOXZh8F3rAmvLQ",
    authDomain: "eql-genphlo-dev.firebaseapp.com",
    projectId: "eql-genphlo-dev",
    storageBucket: "eql-genphlo-dev.appspot.com",
    messagingSenderId: "538219895140",
    appId: "1:538219895140:web:91128b03f6f12a5a578271",
    measurementId: "G-2MM58M6BHF",
  },
  prod: {
    apiKey: "AIzaSyDvCqtAd2iRU0Mb0-mrqws49G-KnjfAMBk",
    authDomain: "eql-genphlo.firebaseapp.com",
    databaseURL:
      "https://eql-genphlo-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "eql-genphlo",
    storageBucket: "eql-genphlo.appspot.com",
    messagingSenderId: "610469408457",
    appId: "1:610469408457:web:defa8d5aabb44a8812a718",
    measurementId: "G-W9D78XEHJB",
  },
};
