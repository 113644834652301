import { Suspense } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import Header from "../Header";
import Footer from "../Footer";
import Loading from "../Loading";
import { useBoundStore } from "../../store";

const Layout = () => {
    const { webUiConfigData } = useBoundStore();

    const location = useLocation();

    const largeScreenPaths = [
        "/legal/privacy",
        "/legal/cookie",
        "/legal/terms",
        "/legal/faq",
        "/legal",
    ];
    const containerSize = largeScreenPaths.includes(location.pathname)
        ? "lg"
        : "sm";

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Header clientLogoUrl={webUiConfigData?.logo_url} />
            <Container
                component="main"
                maxWidth={containerSize}
                sx={{ flex: "1 0 auto" }}
            >
                <Suspense fallback={<Loading />}>
                    <Outlet />
                </Suspense>
            </Container>
            <Box flexShrink="0" mt={3}>
                <Footer clientLogoUrl={webUiConfigData?.logo_url} />
            </Box>
        </Box>
    );
};

export default Layout;
