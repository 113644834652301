import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "genPhlo";

export const client = prismic.createClient(repositoryName, {
    // If your repo is private, add an access token
    accessToken: "",

    // This defines how you will structure URL paths in your project.
    // Update the types to match the Custom Types in your project, and edit
    // the paths to match the routing in your project.
    //
    // If you are not using a router in your project, you can change this
    // to an empty array or remove the option entirely.
    routes: [
        {
            type: "user_terms_page",
            path: "/legal",
        },
        {
            type: "cookie_page",
            path: "/legal/cookie",
        },
        {
            type: "terms_page",
            path: "/legal/terms",
        },
        {
            type: "privacy_page",
            path: "/legal/privacy",
        },
        {
            type: "faq_page",
            path: "/legal/faq",
        },
    ],
});
