import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <Box sx={{ position: "fixed", left: 0, top: 0, right: 0, bottom: 0 }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <CircularProgress
          color="primary"
          size={64}
          aria-label="circular progress loading"
        />
      </Box>
    </Box>
  );
};

export default Loading;
